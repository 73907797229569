<template>
  <notification-item :message="message" @delete-item="deleteItem" @close="close">
    <template v-slot:title>
      <span>{{ $t('_.title') }}</span>
    </template>
    <p class="main-msg">{{ $t('_.statusMessage', { title, teamName }) }}</p>
    <template v-slot:buttons>
      <button @click="goArena">{{ $t(`_.button`) }}</button>
    </template>
  </notification-item>
</template>

<script>
import NotificationItem from '@shared/components/notification/NotificationItem.vue';
import Specific from '@shared/types/Specific';

export default {
  name: 'TournamentCheckinSuccessItem',
  lexicon: 'shared.notification.TOURNAMENT_CHECKIN_SUCCESS',
  components: { NotificationItem },
  props: {
    message: Specific,
  },
  computed: {
    tournament() {
      return this.message?.data?.tournament || {};
    },
    team() {
      return this.message?.data?.team || {};
    },
    title() {
      return this.tournament.title || this.$t('anonymous.arena');
    },
    teamName() {
      return this.team.name || this.$t('anonymous.team');
    },
  },
  methods: {
    goArena() {
      const { tournamentId, gameId, isWcgArena } = this.tournament;
      this.routeMainPath({ path: `${this.$i18n('path')}/arena/${isWcgArena ? 'wcg/' : ''}${tournamentId || gameId}` });
      this.$emit('read-item', this.message || {});
    },
    deleteItem(notificationId) {
      this.$emit('delete-item', notificationId);
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="less">
@import '~@shared/less/proj.less';
</style>
